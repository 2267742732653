import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Contact from "#pages/Contact";

import "./index.css";
import '@radix-ui/themes/styles.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Dashboard from "#pages/Dashboard";
import TrainingStaff from "#pages/TrainingStaff";
import GroupTraining from "#pages/GroupTraining";
import SummerCamp from "#pages/SummerCamp";
import ProgramOverview from "#pages/ProgramOverview";
import Tournament from "#pages/Tournament";
import Academy from "#pages/Academy";
import Overview from "#pages/Overview";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "program",
        element: <ProgramOverview />
      },
      {
        path: "academy",
        element: <Academy />
      },
      {
        path: "tournaments",
        element: <Tournament />
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "staff",
        element: <TrainingStaff />,
      },
      {
        path: "overview",
        element: <Overview />,
      },
      {
        path: "training",
        children: [
          {
            index: true,
            // path: "group",
            element: <GroupTraining />
          },
          {
            path: "camp",
            element: <SummerCamp />,
          },
        ]
      }
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
