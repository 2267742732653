import PageContainer from "#components/PageContainer";
import { styled } from "#theme";

const SectionsContainer = styled("div", {
  display: "grid",
  columnGap: "$5",
  marginBottom: "$6",
  gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
  rowGap: "$5",
});

const SectionHeaderContainer = styled("div", {
  marginBottom: "$2-5",
});

const SectionHeader = styled("h2", {
  fontFamily: "$title",
  fontSize: "$7-5",
  letterSpacing: 0.7,
});

const content = [
  {
    header: "Advanced/Intermediate level",
    description:
      "(USATT, USATT league or DenverPong league rating > 500)",
    times: [
      ["Tuesday", "5:30 - 7:30pm"],
      ["Thursday", "5:30 - 7:30pm"],
      ["Saturday", "2:00 - 4:00pm"],
    ],
  },
  {
    header: "Starter level",
    description:
      "(USATT, USATT league or DenverPong league rating > 500)",
    times: [["Sunday", "9:30 - 11:30am"]],
  },
  {
    header: "Private/Individual Lessons",
    description: "(All levels)",
    paragraph:
      "All days of the week (except during times where we will have group training as mentioned above) we offer private lessons. We will try to accommodate your preferred day and time.",
  },
];

const SectionItem = styled("li", {
  padding: "$1 0",
  span: {
    display: "inline-block",
    marginRight: "$2",
    "&:first-child": { width: "80px" },
  },
});

const Paragraph = styled("p", {
  maxWidth: "800px",
  lineHeight: "$5",
  marginBottom: "$4",
});

function GroupTraining() {
  return (
    <PageContainer title="Group training">
      <SectionsContainer>
        {content.map((section) => {
          return (
            <div>
              <SectionHeaderContainer>
                <SectionHeader>{section.header}</SectionHeader>
                <p>{section.description}</p>
              </SectionHeaderContainer>
              {Array.isArray(section.times) && (
                <ul>
                  {section.times.map(([day, time]) => {
                    return (
                      <SectionItem>
                        <span>{day}</span>
                        <span>{time}</span>
                      </SectionItem>
                    );
                  })}
                </ul>
              )}
              {section.paragraph && <Paragraph>{section.paragraph}</Paragraph>}
            </div>
          );
        })}
      </SectionsContainer>
      <Paragraph>
        All these group training and private lessons can be booked individually.
        Prior registration through email (milehightta@gmail.com) is required for
        all training and lessons.
      </Paragraph>
    </PageContainer>
  );
}

export default GroupTraining;
