import { css, styled } from "#theme";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "#assets/mhtta-logo.png";

import Popover from "./Popover";
import { useMeasure } from "react-use";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";

interface ChildNavigationItemProps {
  to: string;
  label: string;
}

interface NavigationItemProps {
  to?: string;
  label: string;
  children?: ChildNavigationItemProps[];
  icon?: React.ReactNode;
  hideLabel?: boolean;
}

const navigationItems: NavigationItemProps[] = [
  {
    label: "About",
    children: [
      {
        to: "/overview",
        label: "Overview",
      },
      {
        to: "/program",
        label: "Program",
      },
      {
        to: "/academy",
        label: "Academy students",
      },
      {
        to: "/staff",
        label: "Training staff",
      },
    ],
  },
  {
    label: "Tournaments",
    to: "/tournaments",
  },
  {
    label: "Training",
    to: "/training",
    children: [
      {
        to: "/training",
        label: "Training",
      },
      {
        to: "/training/camp",
        label: "Training camp",
      },
    ],
  },
  {
    to: "/contact",
    label: "Contact",
  },
];

const NavContainer = styled("div", {
  padding: "$1 $6",
  height: "88px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "var(--blue-8)",
  color: "$neutral900",
  "@media (max-width: 600px)": {
    padding: "0 $6",
  },
});

const NavListContainer = styled("ul", {
  listStyleType: "none",
  display: "flex",
  flexDireciton: "column",
  justifySelf: "center",
});

const NavItemClass = css({
  display: "inline-flex",
  padding: "$4 $3",
  letterSpacing: 1.3,
  cursor: "pointer",
  fontFamily: "$title",
  textTransform: "uppercase",
  fontSize: "$7",
  alignItems: "center",
  transition: "0.3s ease-in-out color",

  "&:hover": {
    color: "var(--gray-9)",
  },
});

const LogoContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  cursor: "pointer",
});

function getAllItems(
  items: (ChildNavigationItemProps | NavigationItemProps)[],
  cache = new Set<string>()
): ChildNavigationItemProps[] {
  const allItems = [];

  for (const item of items) {
    if (item.to && !cache.has(item.to)) {
      cache.add(item.to);
      allItems.push({
        to: item.to,
        label: item.label,
      });
    }

    if ("children" in item && Array.isArray(item.children)) {
      allItems.push(...getAllItems(item.children, cache));
    }
  }

  return allItems;
}

function Navigation() {
  const [ref, { width }] = useMeasure();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const navigateToHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    if (width <= 600) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [width]);

  const navItems: NavigationItemProps[] = useMemo(() => {
    if (!isCollapsed) {
      return navigationItems;
    }

    return [
      {
        label: "Menu",
        icon: <HamburgerMenuIcon width="2.6rem" height="2.6rem" />,
        children: getAllItems(navigationItems),
        hideLabel: true,
      },
    ];
  }, [isCollapsed]);

  return (
    <NavContainer
      ref={ref as any}
      css={
        pathname === "/"
          ? {
              backgroundColor: "transparent",
              position: "absolute",
              width: "100%",
              zIndex: 2,
            }
          : {
              backgroundColor: "var(--blue-8)",
            }
      }
    >
      <LogoContainer onClick={navigateToHome}>
        <img height="54px" src={logo} alt="logo" />
      </LogoContainer>

      <NavListContainer>
        {navItems.map((navigationItem) => {
          return (
            <NavigationItem
              key={navigationItem.label}
              label={navigationItem.label}
              to={navigationItem.to}
              children={navigationItem.children}
              icon={navigationItem.icon}
              hideLabel={navigationItem.hideLabel}
            />
          );
        })}
      </NavListContainer>
      {width > 600 && <div />}
    </NavContainer>
  );
}

const PopoverListContainer = styled("div", {
  backgroundColor: "var(--blue-8)",
  boxShadow: "$3",
  color: "$neutral900",
});

const PopoverListItemContainer = styled(Link, {
  display: "block",
  padding: "$3 $4",
  cursor: "pointer",
  fontSize: "$6",
  letterSpacing: 1.3,
  fontFamily: "$title",
  transition: "0.3s ease-in-out background-color",

  "&:hover": {
    color: "var(--cyan-6)",
    backgroundColor: "var(--blue-9)",
  },
});

function NavigationItem({
  label,
  children,
  to,
  icon,
  hideLabel,
}: NavigationItemProps) {
  const contentRef = useRef<HTMLDivElement>(null);

  const labelElement = to ? (
    <Link className={NavItemClass()} to={to}>
      {label}
    </Link>
  ) : (
    <div className={NavItemClass()}>
      {icon}
      {!hideLabel && label}
    </div>
  );

  return Array.isArray(children) && children.length > 0 ? (
    <Popover trigger={labelElement} contentRef={contentRef}>
      <PopoverListContainer ref={contentRef}>
        {children.map((child) => {
          return (
            <PopoverListItemContainer to={child.to} key={child.label}>
              <div>{child.label}</div>
            </PopoverListItemContainer>
          );
        })}
      </PopoverListContainer>
    </Popover>
  ) : (
    labelElement
  );
}

export default Navigation;
