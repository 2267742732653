import PageContainer from "#components/PageContainer";
import { styled } from "#theme";
import { v4 } from "uuid";
import { Link as ReactRouterLink } from "react-router-dom";
import { DataType, Information } from "../../types";
import { format } from "date-fns";
import { Box, Link, Tabs, Text, TextProps } from "@radix-ui/themes";
import React from "react";

const ContentContainer = styled("div", {
  display: "grid",
  columnGap: "$4",
  rowGap: "$5",
});

const sections = [
  {
    id: v4(),
    title: "Academy Performance Group",
    dl: [
      { title: "Commitment Duration", value: "12 weeks" },
      {
        title: "Group Training Overview",
        value: "6 hours group training/week (3x2 hours)",
      },
      {
        title:
          "Additional group trainings during school holidays, vacations, etc in addition to academy program	",
        value: "Discounted rate",
      },
      {
        title: "Number of coaches per group training",
        value: "1 coach per 6 players",
      },
      {
        title: "Private Lesson Overview",
        value: "3 hours private lesson/week (2x1.5 hour sessions)",
      },
      {
        title: "Additional private lessons per request",
        value: "Discounted rate",
      },
      {
        title: "Match Play -- Round Robin at Academy or Denver Pong",
        value: "Included in the cost of the academy program",
      },
      { title: "Overall Fees per Week", value: "$350" },
      {
        title: "Amount to be paid at the beginning of every 4 weeks",
        value: "$1,400",
      },
      {
        title: "Coaching at tournaments included in cost?",
        value:
          "Yes, for tournaments designated by the Academy (e.g. US Nationals, US Open, US Team Trials), with a maximum of 30 days per player per year. Additional tournament coaching days available at a discounted rate.",
      },
      {
        title:
          "Travel, lodging, and food expenses for coaches at tournaments included in price?",
        value: "No, will be added to the fees for each player",
      },
      {
        title: "Number of players per coach for tournament coaching",
        value: "Maximum 4",
      },
    ],
  },
  {
    id: v4(),
    title: "Academy Starters Group",
    dl: [
      { title: "Commitment Duration", value: "12 weeks" },
      {
        title: "Group Training Overview",
        value: "2 hours group training/week (1 session)",
      },
      {
        title:
          "Additional group trainings during school holidays, vacations, etc in addition to academy program	",
        value: "Discounted rate",
      },
      {
        title: "Number of coaches per group training",
        value: "1 coach per 6 players",
      },
      {
        title: "Private Lesson Overview",
        value: "1.5 hours private lesson/week",
      },
      {
        title: "Additional private lessons per request",
        value: "Discounted rate",
      },
      {
        title: "Match Play -- Round Robin at Academy or Denver Pong",
        value: "Included in the cost of the academy program",
      },
      { title: "Overall Fees per Week", value: "$160" },
      {
        title: "Amount to be paid at the beginning of every 4 weeks",
        value: "$640",
      },
      {
        title: "Coaching at tournaments included in cost?",
        value:
          "Yes, for tournaments designated by the Academy, with a maximum of 10 days per player per year. Additional tournament coaching days available at a discounted rate.",
      },
      {
        title:
          "Travel, lodging, and food expenses for coaches at tournaments included in price?",
        value: "Will be added to the fees for each player",
      },
      {
        title: "Number of players per coach for tournament coaching",
        value: "Maximum 4",
      },
    ],
  },
  {
    id: v4(),
    title: "Non-Academy Junior Players",
    dl: [
      { title: "Commitment Duration", value: "N/A" },
      { title: "Group Training Overview", value: "$50/session" },
      {
        title:
          "Additional group trainings during school holidays, vacations, etc in addition to academy program	",
        value: "N/A",
      },
      {
        title: "Number of coaches per group training",
        value: "1 coach per 6 players",
      },
      { title: "Private Lesson", value: "$70/hour" },
      { title: "Additional private lessons per request", value: "N/A" },
      {
        title: "Match Play -- Round Robin at Academy or Denver Pong",
        value: "$15, if space permits",
      },
      {
        title: "Overall Fees",
        value: "Prices are per practice or private lesson",
      },
      {
        title: "Coaching at tournaments included in cost?",
        value:
          "Each player will pay $125/day for a shared coach, including travel days (shared with up to 4 players total)",
      },
      {
        title:
          "Travel, lodging, and food expenses for coaches at tournaments included in price?",
        value: "Will be added to the final bill for each player",
      },
      {
        title: "Number of players per coach for tournament coaching",
        value: "Maximum 4",
      },
    ],
  },

  {
    id: v4(),
    title: "Adult Players",
    dl: [
      { title: "Commitment Duration", value: "N/A" },
      { title: "Group Training Overview", value: "$50/session" },
      {
        title:
          "Additional group trainings during school holidays, vacations, etc in addition to academy program	",
        value: "N/A",
      },
      {
        title: "Number of coaches per group training",
        value: "1 coach per 6 players",
      },
      {
        title: "Private Lesson",
        value: "Price varies depending on the coach",
      },
      { title: "Additional private lessons per request", value: "N/A" },
      {
        title: "Match Play -- Round Robin at Academy or Denver Pong",
        value: "$15, if space permits",
      },
      {
        title: "Overall Fees",
        value: "Prices are per practice or private lesson",
      },
      {
        title: "Coaching at tournaments included in cost?",
        value:
          "Each player will pay $125/day for a shared coach, including travel days (shared with up to 4 players total)",
      },
      {
        title:
          "Travel, lodging, and food expenses for coaches at tournaments included in price?",
        value: "Will be added to the final bill for each player",
      },
      {
        title: "Number of players per coach for tournament coaching",
        value: "Maximum 4",
      },
    ],
  },
];

const SectionBody = styled("div", {
  display: "grid",
  rowGap: "$3",
});

const DescriptionTerm = styled("dt", {
  fontWeight: 600,
  width: "260px",
  minWidth: "260px",
  marginRight: "$3",
});

const DescriptionDetails = styled("dd", {
  minWidth: "400px",
});

const DescriptionLine = styled("div", {
  display: "flex",
  width: "100%",

  "@media (max-width: 800px)": {
    flexDirection: "column",
    dt: {
      marginBottom: "$2",
    },
    "> *": {
      width: "100%",
      minWidth: "auto",
    },
  },
});

const List = styled("ul", {
  padding: "0 $4",
  li: {
    padding: "$2 0",
    fontSize: "$5",
    maxWidth: "780px",
    listStyleType: "disc",
  },
});


export function getDisplayValueFromInformation(
  info: Information | DataType
): React.ReactNode {
  switch (info.type) {
    case "compound":
      return (
        <>
          {info.value.flatMap((v) => {
            return getDisplayValueFromInformation(v as any);
          })}
        </>
      );
    case "external-link":
      return (
        <Link href={info.value} target="_blank" rel="noreferrer">
          {info.displayValue ?? info.value}
        </Link>
      );
    case "email":
      return (
        <Link href={`mailto:${info.value}`} target="_blank" rel="noreferrer">
          {info.value}
        </Link>
      );
    case "internal-link":
      return (
        <Link asChild>
          <ReactRouterLink to={info.value}>
            {info.displayValue ?? info.value}
          </ReactRouterLink>
        </Link>
      );
    case "date":
      return format(info.value, "yyyy-M-dd");
    case "date-range":
      return (
        <>
          {format(info.value[0], "yyyy-M-dd")}
          <span style={{ padding: "0 0.25rem" }}>-</span>
          {format(info.value[1], "yyyy-M-dd")}
        </>
      );
    case "list":
      return (
        <List>
          {info.value.flatMap((v) => {
            return <li>{getDisplayValueFromInformation(v as any)}</li>;
          })}
        </List>
      );
    default:
      return info.value as string;
  }
}

export function renderInformation(
  info: Information,
  size: TextProps["size"] = "2",
  width = "500px",
  maxWidth?: string
) {
  return (
    <DescriptionLine>
      <DescriptionTerm>
        <Text size={size}>{info.title}</Text>
      </DescriptionTerm>
      <DescriptionDetails style={{ width, maxWidth }}>
        <Text size={size}>{getDisplayValueFromInformation(info)}</Text>
      </DescriptionDetails>
    </DescriptionLine>
  );
}

const StyledContent = styled(Tabs.Content, {
  padding: "0 $3",
});

function ProgramOverview() {
  return (
    <PageContainer title="Program overview">
      <ContentContainer>
        <Tabs.Root defaultValue={sections[0].id} style={{ overflow: "hidden" }}>
          <Tabs.List mb="5" size="2" style={{ overflowX: "auto" }}>
            {sections.map(({ id, title }) => (
              <Tabs.Trigger
                value={id}
                style={{
                  textAlign: "center",
                  whiteSpace: "pre-wrap",
                }}
              >
                {title}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          <Box>
            {sections.map((section) => {
              return (
                <StyledContent key={section.id} value={section.id}>
                  <SectionBody>
                    {section.dl.map((info) => renderInformation(info, "3", "auto"))}
                  </SectionBody>
                </StyledContent>
              );
            })}
          </Box>
        </Tabs.Root>
      </ContentContainer>
    </PageContainer>
  );
}

export default ProgramOverview;
