import { styled } from "#theme";
import pieke from "#assets/pieke.png";
import timothy from "#assets/timothy.png";
import PageContainer, { PageHeader } from "#components/PageContainer";
import { Box, Flex, Text } from "@radix-ui/themes";

const trainingStaffData = [
  {
    name: "Pieke Franssen",
    title: "National Head Coach & Director",
    qualifiations: [
      "European Champion",
      "Former Dutch Olympic and National Head Coach",
      "Former US National Team Coach",
      "USATT Hopes Program Head Coach (2018, 2019)",
    ],
    imageUrl: pieke,
  },
  {
    name: "Timothy Wang",
    title: "High Performance Coach",
    qualifiations: [
      "10x US National Champion",
      "2x Olympian (2012, 2016)",
      "US Open Champion",
      "North American Champion",
    ],
    imageUrl: timothy,
  },
];

const SectionsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: "$8",
  columnGap: "$4",
});

const StaffNameContainer = styled("h3", {
  fontFamily: "$title",
  fontSize: "$11",
  letterSpacing: 1.1,
  margin: 0,
});

const StaffIdentifierContainer = styled(Box, {});

const QualificationList = styled("ul", {
  paddingLeft: "$3",
  marginTop: "$3",
  marginBottom: "$5",
});

const QualificationListItem = styled("li", {
  listStyleType: "disc",
  listStylePosition: "inside",
  marginBottom: "$2",
});

const StyledStaffContainer = styled(Flex, {

  img: {
    maxWidth: "360px",
    maxHeight: "360px",
  },

  "@media (max-width: 720px)": {
    img: {
      maxWidth: "300px",
      maxHeight: "300px",
    },
  },

});


const Container = styled("div", {
  padding: "$8 $8",
  margin: "0 auto",
  width: "100%",
  maxWidth: "950px",

  "@media (max-width: 600px)": {
    padding: "$6 $4",
  },

  "a": {
    textDecoration: "underline",
    color: "$primary700",
  },
});


function TrainingStaff() {
  return (
    <Container>
      <PageHeader>Training staff</PageHeader>
      <SectionsContainer>
        {trainingStaffData.map(({ name, title, qualifiations, imageUrl }, i) => {
          return (
            <StyledStaffContainer gapY="5" gapX="7" style={{ alignSelf: i ? "flex-end" : "flex-start" }}>
              <img
                alt={name}
                src={imageUrl}
                width="100%"
                height="100%"
              />
              <StaffIdentifierContainer>
                <StaffNameContainer>{name}</StaffNameContainer>
                <Text as="p" size="7" mt="-2">{title}</Text>

                <QualificationList>
                  {qualifiations.map((qualifiation) => {
                    return (
                      <QualificationListItem key={qualifiation}>
                        {qualifiation}
                      </QualificationListItem>
                    );
                  })}
                </QualificationList>
              </StaffIdentifierContainer>
            </StyledStaffContainer>
          );
        })}
      </SectionsContainer>
    </Container>
  );
}

export default TrainingStaff;
