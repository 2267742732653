import SplashMasonry, { MasonryProps } from "#components/SplashMasonry";
import { styled } from "#theme";
import image1 from "#assets/slideshow1.jpg";
import image2 from "#assets/slideshow2.jpg";
import image3 from "#assets/slideshow3.jpg";
import image4 from "#assets/div-b-winners.png";
import sponserSvg from "#assets/joola-logo.svg";
import { ReactComponent as TournamentIcon } from "#assets/tournament.svg";
import { ReactComponent as TrainingIcon } from "#assets/training.svg";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
} from "@radix-ui/themes";
import { academyStudents } from "#pages/Academy/Academy";
import { PersonIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";

const ContentContainer = styled("div", {
  position: "relative",
});

const DashboardContainer = styled("div", {
  display: "grid",
});

const images: MasonryProps["images"] = [
  {
    url: image1,
    alt: "image1",
  },
  {
    url: image2,
    alt: "image2",
  },
  {
    url: image3,
    alt: "image3",
  },
  {
    url: image4,
    alt: "image4",
  },
];

const StyledTitle = styled("p", {
  fontSize: "$10",
  textAlign: "center",
  fontFamily: "$title",
  marginBottom: "$3",
});

const StyledSponsorContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "$5",
  paddingTop: "$9",
  paddingBottom: "$9",
  borderTopWidth: "1px",
  borderTopStyle: "solid",
  borderTopColor: "$neutral400",
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: "$neutral400",
});

const annoucements: {
  priority: 0 | 1 | 2;
  title: string;
  subtitle?: string;
  link: string;
  icon: "tournament" | "training";
}[] = [
  {
    priority: 2,
    title: "Tournament in Fort Collins",
    subtitle: "10/19/2024 - 10/20/2024",
    link: "/tournaments",
    icon: "tournament",
  },
  {
    priority: 2,
    title: "Tournament in Fort Collins",
    subtitle: "10/19/2024 - 10/20/2024",
    link: "/tournaments",
    icon: "training",
  },
];

const StyledAnnouncementGrid = styled(Box, {
  rowGap: "$3",
  columnGap: "$6",
  paddingBottom: "$5",
  display: "flex !important",
  flexWrap: "nowrap",
  overflowX: "auto",

  "@media (max-width: 600px)": {
    paddingBottom: "$5",
  },
});

const StyledAcademyStudentsContainer = styled(Box, {
  backgroundColor: "var(--gray-8)",
  borderTopWidth: "1px",
  borderTopStyle: "solid",
  borderTopColor: "$neutral400",
  padding: "$8 $8",

  "@media (max-width: 600px)": {
    padding: "$6 $5",
  },
});

const StyledOfferings = styled(Box, {
  backgroundColor: "$neutral100",
  borderTopWidth: "1px",
  borderTopStyle: "solid",
  borderTopColor: "$neutral400",
  padding: "$8 $8",

  "@media (max-width: 600px)": {
    padding: "$6 $5",
  },
});

const StyledAnnouncementContainer = styled(Box, {
  borderTopWidth: "1px",
  borderTopStyle: "solid",
  borderTopColor: "$neutral400",
  background: "rgba(255, 255, 255, 0.7)",
  whiteSpace: "nowrap",
  padding: "$5 $8",
  paddingBottom: "0",

  "@media (max-width: 600px)": {
    padding: "$5 $5",
    paddingBottom: "0",
  },
});

const StyledAcademyStudentsList = styled(Grid, {
  gridTemplateColumns: "repeat(auto-fill, minmax(12rem, 1fr)) !important",
  rowGap: "$5",
  userSelect: "none",
});

const StyledAnnouncement = styled("div", {
  display: "flex",
  direction: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "transparent",
  border: "2px solid $neutral600",
  borderRadius: "0.25rem",
  columnGap: "$3",
  padding: "$4 $5",

  button: {
    cursor: "pointer",
  },
});

const List = styled("ul", {
  display: "grid",
  rowGap: "$6",
  columnGap: "$3",
  gridTemplateColumns: "repeat(auto-fill, minmax(auto, 300px))",
  justifyContent: "space-between",
});

const ListItem = styled("li", {
  listStyleType: "\"-  \"",
  listStylePosition: "inside",
});

const offerings: string[] = [
  "Group training",
  "Private lessons",
  "Physical training",
  "Summer/holiday camps",
  "After school programs",
  "Match play",
  "Match coaching in tournaments",
  "Tournaments",
];

function Dashboard() {
  const navigate = useNavigate();

  return (
    <DashboardContainer>
      <ContentContainer>
        <SplashMasonry images={images} />
        <Box
          position="absolute"
          style={{ bottom: 0, zIndex: 2, width: "100%" }}
        >
          <StyledAnnouncementContainer>
            <StyledTitle css={{ textAlign: "left" }}>Upcoming</StyledTitle>
            <StyledAnnouncementGrid>
              {
                // sort by priority
                annoucements.slice(0, 3).map((annoucement, i) => {
                  return (
                    <StyledAnnouncement>
                      {annoucement.icon === "training" ? (
                        <TrainingIcon
                          fill="inherit"
                          width="5.4rem"
                          height="5.4rem"
                        />
                      ) : (
                        <TournamentIcon
                          fill="inherit"
                          width="5.4rem"
                          height="5.4rem"
                        />
                      )}
                      <Flex direction="column">
                        <Heading size="5" align="right" mb="1">
                          {annoucement.subtitle}
                        </Heading>
                        <Text size="4" align="right" as="p">
                          {annoucement.title}
                        </Text>

                        <Button
                          mt="3"
                          size="3"
                          variant="ghost"
                          style={{
                            alignSelf: "flex-end",
                            textTransform: "uppercase",
                            fontWeight: 500,
                          }}
                          onClick={() => navigate(annoucement.link)}
                        >
                          Learn more
                        </Button>
                      </Flex>
                    </StyledAnnouncement>
                  );
                })
              }
            </StyledAnnouncementGrid>
          </StyledAnnouncementContainer>
        </Box>
      </ContentContainer>
      <StyledOfferings>
        <StyledTitle css={{ textAlign: "left" }}>Offerings</StyledTitle>
        <Box mt="6">
          <List>
            {offerings.map((offering) => (
              <ListItem>
                <Text size="5">{offering}</Text>
              </ListItem>
            ))}
          </List>
        </Box>
      </StyledOfferings>
      <StyledAcademyStudentsContainer>
        <StyledTitle css={{ textAlign: "left" }}>Academy students</StyledTitle>
        <StyledAcademyStudentsList mt="6">
          {academyStudents.map((student) => (
            <Flex direction="column" align="center">
              <Avatar
                size="9"
                radius="full"
                fallback={<PersonIcon />}
                src={student.image}
              />
              <Heading mt="1" size="4" style={{ textAlign: "center" }}>
                {student.name}
              </Heading>
            </Flex>
          ))}
        </StyledAcademyStudentsList>
      </StyledAcademyStudentsContainer>
      <StyledSponsorContainer>
        <StyledTitle>Sponsored By:</StyledTitle>
        <div>
          <a href="https://joola.com" target="_blank" rel="noreferrer">
            <img src={sponserSvg} alt="sponser-logo" height="64px" />
          </a>
        </div>
      </StyledSponsorContainer>
    </DashboardContainer>
  );
}

export default Dashboard;
