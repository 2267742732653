import PageContainer, { PageHeader } from "#components/PageContainer";
import { styled } from "#theme";
import { PersonIcon } from "@radix-ui/react-icons";
import { Avatar, Box, Flex, Grid, Heading } from "@radix-ui/themes";
import Suhaan from "#assets/SuhaanLal.png";
import Vikram from "#assets/VikramRao.png";
import Varin from "#assets/VarinChandra.png";
import Cody from "#assets/CodyGao.png";
import Vivaan from "#assets/VivaanChandra.png";
import Miti from "#assets/MitiHegde.png";
import Pardhu from "#assets/PardhuPachuri.png";
import Patrick from "#assets/PatrickFowler.png";
import Rhea from "#assets/RheaRao.png";
import Rishab from "#assets/RishabRao.png";
import Iman from "#assets/ImanLakhani.png";

interface Student {
  name: string;
  image?: string;
}

function shuffle<T>(array: T[]) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
}

export const academyStudents: Student[] = [
  {
    name: "Suhaan Lal",
    image: Suhaan,
  },
  {
    name: "Iman Lakhani",
    image: Iman,
  },
  {
    name: "Patrick Fowler",
    image: Patrick,
  },
  {
    name: "Rhea Rao",
    image: Rhea,
  },
  {
    name: "Rishab Rao",
    image: Rishab,
  },
  {
    name: "Miti Hegde",
    image: Miti,
  },
  {
    name: "Pardhu Pachuri",
    image: Pardhu,
  },
  {
    name: "Varin Chandra",
    image: Varin,
  },
  {
    name: "Vikram Rao",
    image: Vikram,
  },
  {
    name: "Vivaan Chandra",
    image: Vivaan,
  },
];

shuffle(academyStudents);

const StyledSection = styled(Grid, {
  gridTemplateColumns: "repeat(auto-fill, minmax(14rem, 1fr)) !important",
  userSelect: "none",
});

function Academy() {
  return (
    <PageContainer title="Academy Students">
      <StyledSection gapY="7" gapX="7">
        {academyStudents.map((student) => (
          <Flex direction="column" align="center">
            <Avatar
              size="9"
              style={{
                width: "240px",
                height: "240px",
              }}
              radius="full"
              fallback={<PersonIcon />}
              src={student.image}
            />
            <Heading mt="2" size="6" style={{ textAlign: "center" }}>
              {student.name}
            </Heading>
          </Flex>
        ))}
      </StyledSection>
    </PageContainer>
  );
}

export default Academy;
