import { styled } from "#theme";

import logo from "#assets/mhtta-logo.png";
import { Link } from "@radix-ui/themes";

const FooterContainer = styled("div", {
  backgroundColor: "var(--blue-8)",
  padding: "$8 $6",
  display: "grid",
  columnGap: "$8",
  rowGap: "$6",
  // gridAutoFlow: "column",
  gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
  position: "relative",
  color: "$neutral900",

  "@media (max-width: 600px)": {
    padding: "$8 $4",
  },
});

const LeftContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // userSelect: "none",
  "img": {
    height: "88px",
    width: "auto",
  },
});

const RightContainer = styled("div", {
  marginBottom: "$3",

  "@media (max-width: 600px)": {
    display: "grid",
    gridAutoFlow: "column",
    marginBottom: "$4",
  },
});

const CityContainer = styled("p", {
  marginTop: "$1",
  marginBottom: "$3",
});

const TitleContainer = styled("h3", {
  marginBottom: "$1",
  fontFamily: "$title",
  letterSpacing: 0.7,
  fontSize: "$7",
});

const CopyRightContainer = styled("p", {
  position: "absolute",
  bottom: "$4",
  width: "100%",
  textAlign: "center",
  fontSize: "$3",
});

function Footer() {
  return (
    <FooterContainer>
      <LeftContainer>
        <img src={logo} alt="logo" />
      </LeftContainer>
      <RightContainer>
        <div>
        <TitleContainer>Address</TitleContainer>
        <p>6540 Merrimack Drive</p>
        <CityContainer>Castle Pines, CO 80108</CityContainer>
        </div>
        <div>
        <TitleContainer>Email</TitleContainer>
        <p>
          <Link href="mailto:milehightta@gmail.com">milehightta@gmail.com</Link>
        </p>
        </div>

      </RightContainer>
      <CopyRightContainer>© 2024 Mile High Table Tennis Academy</CopyRightContainer>
    </FooterContainer>
  );
}

export default Footer;
