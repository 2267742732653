import PageContainer from "#components/PageContainer";
import { getDisplayValueFromInformation } from "#pages/ProgramOverview/ProgramOverview";
import { styled } from "#theme";
import { Box, Heading, Text } from "@radix-ui/themes";
import { DataType } from "../../types";

const PointsList = styled("ul", {
  padding: "0 $4",
  li: {
    padding: "$2 0",
    fontSize: "$5",
    maxWidth: "780px",
    listStyleType: "disc",
  },
});

type ListSection = {
  title?: string;
  type: "list";
  data: DataType[];
}

type ParagraphSection = {
  title?: string;
  type: "paragraph";
  data: DataType;
}

type Section = ListSection | ParagraphSection;

const sections: Section[] = [
  { 
    // title: "Overview",
    type: "list",
    data: [
      { type: "text", value: "Our goal is to become one of the top training centers for players in the US." },
      { type: "text", value: "We aim to create a group of (aspiring) high performance athletes that succeed at the national and international levels." },
      { type: "text", value: "Further we aim to build a table tennis community where people of all levels and ages can train and compete." }, 
    ],
  },
  {
    type: "paragraph",
    data: { type: "text", value: "We offer a variety of different training options ranging from individual to group sessions focused on the players. The various types of players we are targeting:" },
  },
  {
    type: "list", data: [
      { type: "text", value: "High Performance Athletes" },
      { type: "text", value: "Aspiring High Performance Athletes" },
      { type: "text", value: "Juniors" },
      { type: "text", value: "Adults" },
      { type: "text", value: "Seniors" },
    ]
  }
];


function Vision() {
  function renderSection(section: Section) {
    if (section.type === "list") {
      return (
        <PointsList key={section.title}>
          {section.data.map((point, i) => {
            return <li key={i}>
              <Text size="4">{getDisplayValueFromInformation(point)}</Text>
            </li>
          })}
        </PointsList>
      );
    } else if (section.type === "paragraph") {
      return <Text size="5">{getDisplayValueFromInformation(section.data as any)}</Text>
    }

    return null;
  }

  return (
    <PageContainer title="Overview">
      {sections.map((section) => (
        <Box my="4">
          {section.title && <Heading>{section.title}</Heading>}
          {renderSection(section)}
        </Box>
      ))}
    </PageContainer>
  );
}

export default Vision;
