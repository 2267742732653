import { styled } from "#theme";
import { Box } from "@radix-ui/themes";

export interface MasonryProps {
  images: { url: string; alt: string }[];
}

const ImageRow = styled("div", {
  height: "45vh",
  display: "grid",
  width: "100%",
  columnGap: "$2",

  variants: {
    nth: {
      even: {
        height: "50vh",
        gridTemplateColumns: "63% 35%",
      },
      odd: {
        height: "40vh",
        gridTemplateColumns: "44% 56%",
      },
    },
  },
  defaultVariants: {
    nth: "odd",
  },
});

const Overlay = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(233, 234, 236, 0.6)",
  zIndex: 1,
});

const SlideShowImage = styled("div", {
  // height: "30vh",
  height: "auto",
  // width: "auto",
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto",
  backgroundPosition: "50% 50%",
});

function SplashMasonry(props: MasonryProps) {
  const { images } = props;

  return (
    <Box
      position="relative"
      style={{
        height: "90vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        rowGap: "0.5rem",
        overflow: "hidden",
      }}
    >
      <ImageRow>
        {images.slice(0, 2).map((image) => {
          return (
            <SlideShowImage
              key={image.url}
              style={{ backgroundImage: `url(${image.url})` }}
            />
          );
        })}
      </ImageRow>
      <ImageRow nth="even">
        {images.slice(2, 4).map((image) => {
          return (
            <SlideShowImage
              key={image.url}
              style={{ backgroundImage: `url(${image.url})` }}
            />
          );
        })}
      </ImageRow>
      <Overlay />
    </Box>
  );
}

export default SplashMasonry;
