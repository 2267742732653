import PageContainer from "#components/PageContainer";
import { styled } from "#theme";

const AddressContainer = styled("p", {
  marginBottom: "$5",
});

const TitleContainer = styled("h3", {
  marginBottom: "$1",
  fontFamily: "$title",
  letterSpacing: 0.7,
  fontSize: "$8",
});

const ContentContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
  rowGap: "$5",
  columnGap: "$8",
  fontSize: "$6",
});

function Contact() {
  return (
    <PageContainer title="Contact us">
      <ContentContainer>
        <div>
          <iframe
            title="map"
            width="80%"
            height="360"
            frameBorder="0"
            style={{ border: 0, minWidth: "480px" }}
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCSqmoqQgzFxLNb-SM-Gmlzu2yvSxnE7G4&q=6540+Merrimack+Dr.,+Castle+Pines,+CO+80108"
          ></iframe>
        </div>
        <div>
          <TitleContainer>Address</TitleContainer>
          <AddressContainer>6540 Merrimack Drive, Castle Pines, CO 80108</AddressContainer>
          <TitleContainer>Email</TitleContainer>
          <p><a href="mailto:milehightta@gmail.com">milehightta@gmail.com</a></p>
        </div>
      </ContentContainer>
    </PageContainer>
  );
}

export default Contact;
